.pageRoot {
  /* padding-bottom: 90px; */

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.root {
  width: 100%;
  display: flex;
  padding: 46px;
  gap: 2rem;

  @media(max-width: 1024px) {
    padding: 0px;
    flex-wrap: wrap;
  }
}

.actionBar {
  /* padding: 2rem; */
}


.listingBox {
  width: 75%;
  display: block;
  overflow: hidden;
  padding: 48px 100px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%);

  @media(max-width: 1300px) {
    padding: 35px 40px;
  }

  @media(max-width: 1024px) {
    width: 100%;
  }

  @media(max-width: 768px) {
    box-shadow: none;
    padding: 0;
  }
}

.leftSide {
  width: 25%;

  @media(max-width: 1024px) {
    width: 100%;
  }

  @media(max-width:768px) {
    padding: 0 24px;
  }
}